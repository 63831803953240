
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { DataTable } from "simple-datatables";
import { defineComponent } from 'vue';

interface Data {
    id: string;
    name: string;
    slug: string;
    description: string;
}

const getApi = (_url: string) => {
    return fetch(_url, {
        method: "GET",
        mode: "cors",
        credentials: "include"
    });
};

const getJson = async (el: Element): Promise<void> => {
    const response = await getApi("https://rsk-api.servehttp.com/Bookstack/books.php");
    const data = await response.json();
    console.log(data);
    const _data = data.data.map((obj: Data) => [obj.id, obj.name, obj.slug, obj.description, obj.id]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _datatable = new DataTable(el, {
        data: { data: _data },
        perPage: 50,
        searchable: true,
        fixedHeight: false,
        perPageSelect: [50, 100],
        columns: [
            {
                select: 4,
                render: (data: string) => {
                    return "<a href='https://rsk-api.servehttp.com/Bookstack/export.php?id=" + data + "'  target='_blank'>view html</a>";
                }
            }
        ]
    });
};

export default defineComponent({
    data () {
        return {};
    },
    mounted () {
        const el = document.querySelector(".app-table-data");
        if (el) {
            getJson(el);
        }
    }
});
