<template>
    <div class="row">
        <div class="col-12 col-md-10 col-xl-3 col-lg-6">
            <div class="text-white m-2 bg-primary">
                <div class="p-2 m-2">

                    <h4 class="mb-0">
                        <span id="app.dashboard.lastweek"></span>
                    </h4>
                    <p class="text-light">Lasta semajno</p>

                    <div class="chart-wrapper px-0" style="height:70px;">
                        <canvas id="app.dashboard.chart.lastweek"></canvas>
                    </div>

                </div>

            </div>
        </div>

        <div class="col-12 col-md-10  col-xl-3 col-lg-6">
            <div class="text-white m-2 bg-danger">
                <div class="p-2 m-2">

                    <h4 class="mb-0">
                        <span id="app.dashboard.thisweek"></span>
                    </h4>
                    <p class="text-light">Ĉi-semajno</p>

                    <div class="chart-wrapper px-0" style="height:70px;">
                        <canvas id="app.dashboard.chart.thisweek"></canvas>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-12 col-md-10  col-xl-3 col-lg-6">
            <div class="text-white m-2 bg-warning" style="min-height:154px">
                <div class="p-2 m-2">

                    <h4 class="mb-0">
                        <p class="text-light">Hieraŭ</p>
                        <span id="app.dashboard.yesterday">{{yesterday}}</span>
                    </h4>

                    <!--div class="chart-wrapper px-0" style="height:70px;">
                        <canvas id="app.dashboard.chart.thisweek"></canvas>
                    </div-->

                </div>

            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-12 col-md-10 col-xl-3 col-lg-6">
            <!--div class="card"-->
                <div class="p-2 m-2 border rounded" style="height:150px">
                    <div class="stat-widget">
                        <div class="stat-icon">
                            <!--i class="layout-grid2 text-primary border-primary">&nbsp;</i-->
                            <i class="fas fa-piggy-bank"></i>
                        </div>
                        <div class="stat-content">
                            <div class="stat-text">
                            <!--?php echo $json[0]["title"]; ?-->
                            {{noto?.[0]?.title}}
                            </div>
                            <div class="stat-digit">
                            {{noto?.[0]?.value}}
                            <!--?php echo $json[0]["value"]; ?-->
                            </div>
                        </div>
                    </div>
                <!--/div-->
            </div>
        </div>

        <div class="col-12 col-md-10 col-xl-3 col-lg-6">
            <!--div class="card"-->
                <div class="p-2 m-2 border rounded" style="height:150px">
                    <div class="stat-widget">
                        <div class="stat-icon">
                            <!--i class="money text-danger border-danger">&nbsp;</i-->
                            <i class="fas fa-credit-card"></i>
                        </div>
                        <div class="stat-content">
                            <div class="stat-text">
                            <!--?php echo $json[1]["title"]; ?-->
                            {{noto?.[1]?.title}}
                            </div>
                            <div class="stat-digit">
                            <!--?php echo $json[1]["value"]; ?-->
                            {{noto?.[1]?.value}}
                            </div>
                        </div>
                    </div>
                </div>
            <!--/div-->
        </div>

        <div class="col-12 col-md-10 col-xl-3 col-lg-6">
            <!--div class="card"-->
                <div class="p-2 m-2 border rounded" style="height:150px">
                    <div class="stat-widget">
                        <div class="stat-icon">
                            <!--i class="credit-card text-warning border-warning">&nbsp;</i-->
                            <i class="fa fa-money-check"></i>
                        </div>
                        <div class="stat-content">
                            <div class="stat-text">
                            <!--?php echo $json[2]["title"]; ?-->
                            {{noto?.[2]?.title}}
                            </div>
                            <div class="stat-digit">
                            <!--?php echo $json[2]["value"]; ?-->
                            {{noto?.[2]?.value}}
                            </div>
                        </div>
                    </div>
                <!--/div-->
            </div>
        </div>

        <div class="col-12 col-md-10 col-xl-3 col-lg-6">
            <!--div class="card"-->
                <div class="p-2 m-2 border rounded" style="height:150px">
                    <div class="stat-widget">
                        <div class="stat-icon">
                            <!--i class="wallet text-success border-success">&nbsp;</i-->
                            <i class="fa fa-money-check-alt"></i>
                        </div>
                        <div class="stat-content">
                            <div class="stat-text">
                            <!--?php echo $json[3]["title"]; ?-->
                            {{noto?.[3]?.title}}
                            </div>
                            <div class="stat-digit">
                            <!--?php echo $json[3]["value"]; ?-->
                            {{noto?.[3]?.value}}
                            </div>
                        </div>
                    </div>
                <!--/div-->
            </div>
        </div>

    </div>

</template>
<script lang="ts">
import { defineComponent } from 'vue';
import {
    WeekModel, ChartView, Presenter,
    getApi,
    hostname,
    mon as lastmon,
    sun as lastsun,
    thismon,
    thissun,
    yesterday
} from "@/functions/chart";

export default defineComponent({
    name: 'Chart',
    data () {
        return {
            noto: [],
            yesterday: 0
        };
    },
    mounted () {
        const _lastweek = new WeekModel(lastmon, lastsun);
        const _lastmodel = _lastweek.getJson();
        const _id = "app.dashboard.chart.lastweek";
        const _lastview = new ChartView(_id);

        const _thisweek = new WeekModel(thismon, thissun);
        const _thismodel = _thisweek.getJson();
        const _thisview = new ChartView("app.dashboard.chart.thisweek");

        let presenter = null;
        presenter = new Presenter(_lastview);
        presenter.setModel(_lastmodel, null, null);
        presenter.setSum("app.dashboard.lastweek", _lastmodel);

        presenter = new Presenter(_thisview);
        presenter.setModel(_thismodel, null, null);
        presenter.setSum("app.dashboard.thisweek", _thismodel);

        const getJson = async () => {
            const start = yesterday;
            const end = yesterday;
            const url = hostname + "/Record/range.php?start=" + start + "&end=" + end;
            const response = await getApi(url);
            const data = await response.json();
            this.yesterday = data?.["datasets"][0]["data"][0];
        };
        const getNoto = async () => {
            try {
                const url = "https://rasikyou.ddns.me/config/noto.json";
                const response = await getApi(url);
                const data = await response.json();
                this.noto = data;
                // console.log(data);
            } catch (e) {
                // console.log(e.message);
            }
        };

        getJson();
        getNoto();
    }
});
</script>
