<template>
    <div class="bg-dark" style="height: 100vh">
        <div class="d-flex align-content-center flex-wrap">
            <div class="container">
                <div class="login-content">
                    <!--div class="login-logo">
                        <a href="index.html">
                            <img class="align-content" src="images/logo.png" alt="">
                        </a>
                    </div-->
                    <div class="login-form">
                        <!--form method="post" novalidate="novalidate"-->
                            <div class="form-group">
                                <label>Uzantnomo</label>
                                <input id="app.login.username" name="username" type="text" class="form-control" placeholder="Username"/>
                            </div>
                            <div class="form-group">
                                <label>Pasvorto</label>
                                <input id="app.login.password" name="password" type="password" class="form-control" placeholder="Password"
                                    v-on:keyup.enter="postLogin"
                                />
                            </div>
                            <div class="form-group">
                                <label>Lingvo</label>
                                <select id="app.login.lang" name="lang" class="form-control">
                                    <option value="none">Select Lang</option>
                                    <option value="eo">Esperanto</option>
                                    <option value="ja">Japanese</option>
                                    <option value="en">English</option>
                                </select>
                            </div>

                            <button
                              id="app.login.button"
                              type="submit"
                              class="btn btn-success btn-flat m-b-30 m-t-30"
                              v-on:click="postLogin"
                              >
                              Ensaluti
                            </button>
                        <!--/form-->
                    </div>
                </div>
            </div><!-- div class="container" -->
        </div>
    </div>
    <!-- Load font awesome icons -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

    <!-- The social media icon bar -->
    <div class="icon-bar">
        <a href="https://rasikyou.ddns.me/reader/" class="book" target='_blank'><i class="fa fa-book"></i></a>
        <a href="https://rasikyou.ddns.me/calendar/" class="calendar" target='_blank'><i class="fa fa-calendar"></i></a>
    </div>
</template>
<style>
 /* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
  position: fixed;
  top: 90%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
  background-color: #000;
}

.book {
  background: #3B5998;
  color: white;
}

.calendar {
  background: #dd4b39;
  color: white;
}

</style>
<script lang="ts">
import { defineComponent } from 'vue';

interface User {
    username: string;
    password: string;
    lang: string;
}

export default defineComponent({
    name: 'Login',
    methods: {
        goToDashboard: function () {
            this.$router.push('/dashboard');
        },
        postLogin: function () {
            console.log("test");
            const hostname = "https://rsk-api.servehttp.com";
            const _url = hostname + "/User/login.php";
            // eslint-disable-next-line
            const _uname = (document.getElementById("app.login.username") as any).value;
            // eslint-disable-next-line
            const _passwd = (document.getElementById("app.login.password") as any).value;
            // eslint-disable-next-line
            const _lang = (document.getElementById("app.login.lang") as any).value;
            const _json: User = { username: _uname, password: _passwd, lang: _lang !== "none" ? _lang : "" };
            const _changePage = this.goToDashboard;

            fetch(_url, {
                method: 'post',
                mode: "cors",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_json)
            }).then(function (response) {
                return response.json();
            }).then(function () {
                fetch("/user/access.php", {
                    method: 'post',
                    mode: "cors",
                    credentials: "same-origin",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(_json)
                });
                _changePage();
            });
        }
    }
});
</script>
<style>
  @import "https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css";
  @import "https://rasikyou.ddns.me/assets/css/style.css";
</style>
