const getApi = (_url: string) => {
    return fetch(_url, {
        method: "GET",
        mode: "cors",
        credentials: "include"
    });
};
const hostname = "https://rsk-api.servehttp.com";

const HEADINGS = [
    'Dato',
    'Matenmanĝo',
    'Tagmanĝo',
    'Vespermanĝo',
    'Alia manĝaĵo',
    'Manĝaĵo Rimarko',
    'Vivo',
    'Rimarko',
    'Trafiko',
    'Rimarko',
    'Kafo',
    'Rimarko',
    'Libro',
    'Rimarko',
    'IT',
    'Rimarko',
    'Alio',
    'Rimarko',
    'Mono'
];

class TableDaysModel {
    private _url: string | null = '';
    constructor (year: string, month: string) {
        if (year && month) {
            this._url = hostname + "/Record/dayslist.php?year=" + year + "&month=" + month;
        } else {
            this._url = null;
        }
    }

    async getJson () {
        if (this._url) {
            const response = await getApi(this._url);
            const _data = await response.json();

            return {
                data: {
                    headings: [
                        "Year",
                        "Date",
                        "Cost",
                        "View Detail"
                    ],
                    data: _data.data
                },
                columns: [
                    {
                        select: 3,
                        render: (data: string /*, cell, row */) => {
                            return "<a href='" + data + "'>JSON</a>";
                        }
                    }
                ],
                perPage: 15,
                perPageSelect: [10, 15, 20, 31],
                footer: true
            };
        } else {

        }
    }
}

class TableMonthModel {
    private _url: string | null = '';
    constructor (year: string, month: string) {
        if (year && month) {
            this._url = hostname + "/Record/month.php?year=" + year + "&month=" + month;
        } else {
            this._url = null;
        }
    }

    async getJson () {
        if (this._url) {
            const response = await getApi(this._url);
            const _data = await response.json();

            return {
                data: {
                    headings: HEADINGS,
                    data: _data.data
                },
                columns: [
                    {
                        select: [1, 2, 3, 4, 6, 8, 10, 12, 14, 16],
                        render: (data: string /*, type, row */) => {
                            try {
                                // console.log(parseFloat(data));
                                if (parseFloat(data) < 0.01) {
                                    return "";
                                } else {
                                    return data;
                                }
                            } catch (e) {
                                return data;
                            }
                        }
                    }, {
                        select: [1], hidden: true // breakfast
                    }, {
                        select: [2], hidden: true // lunch
                    }, {
                        select: [3], hidden: true // dinner
                    }, {
                        select: [4], hidden: true // otherfood
                    }
                ],
                perPage: 31,
                perPageSelect: [31, 15, 10]
                // footer: true
            };
        } else {

        }
    }
}

class TableWeekModel {
    private _url: string | null = '';
    constructor (week: string) {
        if (week) {
            this._url = hostname + "/Record/week.php?week=" + week;
        } else {
            this._url = null;
        }
    }

    async getJson () {
        if (this._url) {
            const response = await getApi(this._url);
            const _data = await response.json();

            return {
                data: {
                    headings: HEADINGS,
                    data: _data.data
                },
                columns: [
                    {
                        select: [1, 2, 3, 4, 6, 8, 10, 12, 14, 16],
                        render: (data: string /*, type, row */) => {
                            try {
                                // console.log(parseFloat(data));
                                if (parseFloat(data) < 0.01) {
                                    return "";
                                } else {
                                    return data;
                                }
                            } catch (e) {
                                return data;
                            }
                        }
                    }, {
                        select: [1], hidden: true // breakfast
                    }, {
                        select: [2], hidden: true// lunch
                    }, {
                        select: [3], hidden: true // dinner
                    }, {
                        select: [4], hidden: true // other food
                    }
                ],
                perPage: 7,
                perPageSelect: [7]
                // footer: true
            };
        } else {

        }
    }
}

export {
    TableDaysModel,
    TableWeekModel,
    TableMonthModel
};
