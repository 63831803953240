import moment from "moment";
// import {Chart} from "vue-chartjs";
import Chart from "chart.js";
const hostname = "https://rsk-api.servehttp.com";

const getApi = (_url: string) => {
    return fetch(_url, {
        method: "GET",
        mode: "cors",
        credentials: "include"
    });
};

// eslint-disable-next-line
type ModelData = any;

enum lastWeek{
    mon,
    tue,
    wed,
    thu,
    fri,
    sat,
    sun
}

const thismon = moment().startOf("isoWeek").format("YYYY-MM-DD");
const thissun = moment().endOf("isoWeek").format("YYYY-MM-DD");
const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

let mon: string = lastWeek[lastWeek.mon];
mon = moment().subtract(1, "weeks").startOf('isoWeek').format("YYYY-MM-DD");

let tue: string = lastWeek[lastWeek.tue];
tue = moment().isoWeekday(-5).format("YYYY-MM-DD");

let wed: string = lastWeek[lastWeek.wed];
wed = moment().isoWeekday(-4).format("YYYY-MM-DD");

let thu: string = lastWeek[lastWeek.thu];
thu = moment().isoWeekday(-3).format("YYYY-MM-DD");

let fri: string = lastWeek[lastWeek.fri];
fri = moment().isoWeekday(-2).format("YYYY-MM-DD");

let sat: string = lastWeek[lastWeek.sat];
sat = moment().isoWeekday(-1).format("YYYY-MM-DD");

let sun: string = lastWeek[lastWeek.sun];
sun = moment().subtract(1, "weeks").endOf('isoWeek').format("YYYY-MM-DD");

/* begin of model */
class WeekModel {
    private _url: string;
    private _mon: string;
    private _sun: string;

    constructor (_mon: string, _sun: string) {
        this._mon = _mon;
        this._sun = _sun;
        this._url = hostname + "/Record/range.php?start=" + _mon + "&end=" + _sun;
    }

    async getJson () {
        const response = await getApi(this._url);
        const _data = await response.json();
        const week = _data.datasets[0].data;

        return {
            type: 'line',
            data: {
                labels: [
                    '月曜日 ' + (this._mon === mon ? mon /* last mon */ : this._mon),
                    '火曜日 ' + (this._mon === mon ? tue /* last tue */ : moment().startOf("isoWeek").add(1, 'd').format("YYYY-MM-DD")),
                    '水曜日 ' + (this._mon === mon ? wed /* last wed */ : moment().startOf("isoWeek").add(2, 'd').format("YYYY-MM-DD")),
                    '木曜日 ' + (this._mon === mon ? thu /* last thu */ : moment().startOf("isoWeek").add(3, 'd').format("YYYY-MM-DD")),
                    '金曜日 ' + (this._mon === mon ? fri /* last fri */ : moment().startOf("isoWeek").add(4, 'd').format("YYYY-MM-DD")),
                    '土曜日 ' + (this._mon === mon ? sat /* last sat */ : moment().startOf("isoWeek").add(5, 'd').format("YYYY-MM-DD")),
                    '日曜日 ' + (this._sun === sun ? sun /* last sun */ : this._sun)
                ],
                type: 'line',
                datasets: [{
                    data: week,
                    label: "$",
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255,255,255,.55)'
                }]
            },
            options: {
                maintainAspectRatio: false,
                legend: { display: false },
                responsive: true,
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: 'transparent',
                            zeroLineColor: 'transparent'
                        },
                        ticks: {
                            fontSize: 2,
                            fontColor: 'transparent'
                        }
                    }],
                    yAxes: [{
                        display: false,
                        ticks: { display: false }
                    }]
                },
                title: { display: false },
                elements: {
                    line: {
                        tension: 0.00001,
                        borderWidth: 1
                    },
                    point: {
                        radius: 4,
                        hitRadius: 10,
                        hoverRadius: 4
                    }
                }
            }

        };
    }
}

class ChartWeekModel {
    private _url: string;

    constructor (start: string, end: string) {
        this._url = hostname + "/Record/range.php?start=" + start + "&end=" + end;
    }

    async getJson () {
        const response = await getApi(this._url);
        const _data = await response.json();

        return {
            type: 'bar',
            data: _data,
            options: { scales: { yAxes: [{ ticks: { beginAtZero: true } }] } }
        };
    }
}

class ChartWeekModel2 {
    private _url: string;

    constructor (start: string, end: string) {
        this._url = hostname + "/Record/range.php?start=" + start + "&end=" + end;
    }

    async getJson () {
        const response = await getApi(this._url);
        const _data = await response.json();

        _data.datasets[0].fill = false;

        return {
            type: 'line',
            data: _data,
            options: {
                maintainAspectRatio: false,
                legend: { display: false },
                responsive: true,
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: 'transparent',
                            zeroLineColor: 'transparent'
                        },
                        ticks: {
                            fontSize: 1,
                            fontColor: 'transparent',
                            display: false
                        }
                    }]
                    /* yAxes: [ {
                        stacked: false
                        display:false,
                        ticks: { display: false, }
                    } ] */
                },
                title: { display: false },
                elements: {
                    line: {
                        tension: 0.00001,
                        borderWidth: 1
                    },
                    point: {
                        radius: 4,
                        hitRadius: 10,
                        hoverRadius: 4,
                        pointStyle: 'rectRot'
                    }
                }
            }
        };
    }
}

class ChartMonthModel {
    private _url: string;

    constructor (year: string, month: string) {
        const lang = "eo";
        this._url = hostname + "/Record/percentage.php?year=" + year + "&month=" + month + "&lang=" + lang;
    }

    async getJson () {
        const response = await getApi(this._url);
        const _data = await response.json();

        return {
            type: 'doughnut',
            data: _data,
            options: {
                responsive: true,
                legend: { display: true }
            }
        };
    }
}

class ChartYearModel {
    private _url: string;

    constructor (year: string) {
        this._url = hostname + "/Record/year.php?year=" + year;
    }

    async getJson () {
        const response = await getApi(this._url);
        const _data = await response.json();

        return {
            type: 'horizontalBar',
            data: _data,
            options: { scales: { yAxes: [{ ticks: { beginAtZero: true } }] } }
        };
    }
}
/* end of model */

class Presenter {
    private _view: ChartView;
    private _model: ModelData;
    // private title;
    private sum: number;

    constructor (_view: ChartView) {
        this._view = _view;
        this.sum = 0;
    }

    async setModel (_model: ModelData, element: HTMLElement|null, title: string|null) {
        try {
            this._model = await _model;
            this._view.setModel(this._model);
            if (element) {
                const range = this._model.data.datasets[0].data;
                const sum = range.reduce((total: number, num: number) => { return total + num; });
                this.sum = sum;
                element.innerHTML = title + " (" + this.sum + ")";
            }
        } catch (e) {
            console.log(e.message);
        }
    }

    async setSum (_id: string, _model: ModelData) {
        const _range = await _model;
        const range = _range.data.datasets[0].data;
        const sum: number = range.reduce((total: number, num: number) => { return total + num; });
        this.sum = sum;

        this._view.setSum(_id, sum.toString());
    }

    async setPlugin (_model: ModelData) {
        // eslint-disable-next-line
      const text = async (data: any): Promise<number> => {
            const _data = await data;
            return _data.data.datasets[0].data.reduce((total: number, num: number) => total + num);
        };
        const _txt = await text(_model);
        this._view.setPlugin(_txt);
    }
}

class ChartView {
    private ctx: HTMLCanvasElement;
    constructor (_id: string) {
        this.ctx = document.getElementById(_id) as HTMLCanvasElement;
    }

    setModel (_model: ModelData) {
        // eslint-disable-next-line
        const myChart = new Chart(this.ctx, _model);
    }

    setSum (_id: string, _sum: string) {
        const lastweek = document.getElementById(_id);
        if (lastweek) {
            lastweek.innerHTML = _sum;
        }
    }

    async setPlugin (_txt: number) {
        Chart.pluginService.register({
        // eslint-disable-next-line
        beforeDraw: (chart: any) => {
                const width = chart.chart.width;
                const height = chart.chart.height;
                const ctx = chart.chart.ctx;

                ctx.restore();
                const fontSize = (height / 114).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "middle";

                const adjustX = 15;
                const textX = Math.round((width - ctx.measureText(_txt).width) / 2) + adjustX;
                const adjustY = 30;
                const textY = height / 2 + adjustY;

                ctx.fillText(_txt?.toFixed(), textX, textY);
                ctx.save();
            }
        });
    }
}

export {
    ChartView,
    Presenter,

    ChartYearModel,
    ChartMonthModel,
    ChartWeekModel2,
    ChartWeekModel,
    WeekModel,
    // last_week
    lastWeek,
    mon,
    sun,
    thismon,
    thissun,
    yesterday,
    getApi,
    hostname
};
