<template>
<div class="content" style="width: 100%; position: static">

    <!--div v-bind:is='view'></div-->
    <div class="animated fadeIn">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-10 col-lg-6">
                    <div class="card">
                        <div class="card-header" style="height:65px">
                        <strong class="card-title">
                            <span class="btn btn-secondary" v-if=" view === 'insert' || view === 'update' " @click="prev()">
                                <i class="fas fa-chevron-left">&nbsp;</i>
                                Prev
                            </span>
                            &nbsp;
                        </strong>
                        </div>
                        <div class="card-body">

                            <div class="card-title">
                                <h3 class="text-center" v-if=" view === 'insert' || view === 'update' ">{{ title }}</h3>
                                <h3 class="text-center" v-else>{{lang.update}}</h3>
                            </div>
                            <hr/>
                            <div class="form-group">
                                <label for="app.record.date" class="control-label mb-1">{{lang.date}}</label>
                                <input id="app.record.date" name="date" type="date" class="form-control" min="" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" placeholder=""
                                    v-model="date" v-bind:readonly="readonly"
                                    required="">
                                </div>
                            <div class="form-group">
                                <label for="app.record.type" class="control-label mb-1">{{lang.type}}</label>
                                <select name="pr-type" id="app.record.type"
                                    v-model="type" v-bind:readonly="readonly"
                                    class="form-control-sm form-control">

                                    <option v-for="opt in options" :key="opt.id" :value="opt.id">
                                        {{opt.content}}
                                    </option>

                                </select>
                            </div>

                            <div class="form-group" v-if=" view === 'insert' || view === 'update' ">
                                <label for="app.record.amount" class="control-label mb-1">{{lang.amount}}</label>
                                <input id="app.record.amount"
                                    v-model="amount"
                                    name="pr-amount" type="number" class="form-control" min="" pattern="^\d+(\.)\d{1,2}$" placeholder="" required="">
                            </div>

                            <div class="form-group" v-if=" view === 'insert' || view === 'update' ">
                            <label for="app.record.comment" class="control-label mb-1">{{lang.comment}}</label>
                                <span class="form-inline">
                                    <input id="app.record.comment"
                                        v-model="comment"
                                        @change="changeComment($event)"
                                        name="pr-comment"
                                        type="text"
                                        class="form-control form-group"
                                        min="" pattern="" placeholder=""
                                        style="width:80%">
                                    <button class="btn btn-outline-warning form-group float-right" style="width:20%" @click="tolower()">
                                        <span style="margin-left:auto;margin-right:auto;white-space: nowrap;">To Lower</span>
                                    </button>
                                </span>
                                <span class="mt-1 form-inline">
                                    <span style="width:80%">Predict : {{predict}}</span>
                                    <button class="btn btn-outline-primary form-group float-right" style="width:20%" @click="clear()">
                                        <span style="margin-left:auto;margin-right:auto;white-space: nowrap;">Clear</span>
                                    </button>
                                </span>
                                <span class="mt-1 form-inline">
                                    <span class="ml-3 text-danger">/(\[)[a-z0-9A-Z_-\u4E00-\u9FFF]*(\])/</span>
                                </span>
                            </div>

                            <hr/>

                            <div class="form-group" v-if=" view === 'index' ">
                                <button id="app.record.detect" type="submit" name="" value="true" class="btn btn-lg btn-info btn-block" @click="detect()">
                                    <span>{{lang.enter}}</span>
                                </button>
                            </div>

                            <div class="form-group" v-if=" view === 'insert' ">
                                <button id="app.record.add" type="submit" name="" value="true" class="btn btn-lg btn-info btn-block" @click="update(false); loading($event);">
                                    <span>{{lang.add}}</span>
                                </button>
                            </div>

                            <div class="form-group" v-if=" view === 'update' ">
                                <button id="app.record.update" type="submit" name="" value="true" class="btn btn-lg btn-info btn-block" @click="update(false); loading($event);">
                                    <span>{{lang.update}}</span>
                                </button>
                            </div>

                            <div class="form-group" v-if=" view === 'update' ">
                                <button id="app.record.cal" type="submit" name="cal" value="true" class="btn btn-lg btn-primary btn-block" @click="update(true); loading($event);">
                                    <span>{{lang.calculate}}</span>
                                </button>
                            </div>

                        </div><!-- .card-body -->
                    </div><!-- .card -->
                </div><!-- .col -->
            </div><!-- class="row" -->
        </div><!-- div class="container-fluid" -->
    </div>

</div><!-- div class="content" -->
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import moment from "moment";

const hostname = "https://rsk-api.servehttp.com";

const getApi = (_url: string) => {
    return fetch(_url, {
        method: "GET",
        mode: "cors",
        credentials: "include"
    });
};

// eslint-disable-next-line
const api = async (_json: any, _url: string, _method: string) => {

    let template = null;

    await fetch(_url, {
        method: _method,
        mode: "cors",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(_json)
    }).then((response) => {
        return response.json();
    }).then((_data) => {
        if (_data?.["status"] === true) {
            template = "index";
        } else {
            alert("failure");
            template = null;
        }
    });
    return template;
};

const setType = (_type: string) => {
    let prType;
    let prComment;

    if (typeof _type === 'string') {
        // prType = _type?.match(/^[a-z_]{2,14}$/)?.[0] ?? '';
        prType = _type?.match(/^([a-z]{2,14})(_[a-z]{2,14})?$/)?.[1] ?? '';
        prComment = prType + "_comment";
    } else {
        prType = "none";
        _type = "none";
        prComment = "";
    }
    // const obj = { "pr-type": prType, "pr-comment": prComment };
    const obj = { "pr-type": _type, "pr-comment": prComment };
    console.log(obj);
    return obj;
};

export default defineComponent({
    data () {
        return {
            view: 'index',
            readonly: false,
            title: '',
            date: new Date().toISOString().slice(0, 10),
            amount: 0,
            year: '',
            comment: '',
            type: 'none',
            predict: '',
            lang: {
                update: 'Ĝisdatigi',
                date: 'Dato',
                enter: 'Eniri',
                calculate: 'Kalkuli',
                add: 'Adici',
                type: 'Tipo',
                amount: 'Sumo',
                comment: 'Komento'
            },
            options: [
                { id: 'none', content: "Bonvolu elekti" },
                { id: 'food_breakfast', content: "Matenmanĝo" },
                { id: 'food_lunch', content: "Tagmanĝo" },
                { id: 'food_dinner', content: "Vespermanĝo" },
                { id: 'food_other', content: "Alia manĝaĵo" },
                { id: 'live', content: "Vivo" },
                { id: 'traffic', content: "Trafiko" },
                { id: 'coffee', content: "Kafo" },
                { id: 'book', content: "Libro" },
                { id: 'it', content: "IT" },
                { id: 'other', content: "Alio" }
            ]
        };
    },
    methods: {
        // eslint-disable-next-line
        loading (e: any){
            e.target.innerHTML = '<span>...Ŝarĝanti</span>';
        },
        // eslint-disable-next-line
        changeComment (e: any){
            const val = e.target.value;
            if (val.length > 0) {
                e.target.value = val.replace(/(［|］|，)/g, (s: string) => {
                    // console.log(s.charCodeAt(0));
                    if (s.charCodeAt(0) === 65339) { // left square brackets
                        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
                    } else if (s.charCodeAt(0) === 65341) { // right square brackets
                        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
                    } else if (s.charCodeAt(0) === 65292) { // comma
                        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
                    }
                    return s;
                });
            }

            try {
                this.predict = typeof e.target.value === 'string' ? e.target.value.replace(/(\[)[a-z0-9A-z_\-\u4E00-\u9FFF]*(\])/g, '') : '';
            } catch (e) {
                console.log(e.message);
            }
        },
        detect () {
            const swAddOrUpdate = (_year: string, _date: string, _type: string) => {
                const url = hostname + "/Record/day.php?year=" + _year + "&date=" + _date;
                this.title = this.date + " (" + moment(this.date).format('dddd') + ")";

                getApi(url).then((response) => {
                    return response.json();
                }).then((_data) => {
                    // const state = { id : 1 };
                    if (_type !== 'none') {
                        if (_data.length === 0) {
                            // insert

                            this.view = "insert";
                            this.year = _year;
                            this.readonly = true;

                            this.amount = 0;
                            this.comment = "";
                        } else {
                            // update

                            this.view = "update";
                            this.year = _year;
                            this.readonly = true;

                            const obj = setType(this.type);
                            this.amount = _data[obj["pr-type"]];
                            this.comment = _data[obj["pr-comment"]];
                        }
                    }
                });
            };

            const year = this.date.substring(0, 4);
            const date = this.date.substring(5, 10);

            swAddOrUpdate(year, date, this.type);
        },
        tolower () {
            this.comment = typeof this.comment === "string" ? this.comment.toLowerCase() : this.comment;
            if (typeof this.comment === "string") {
                this.comment = this.comment.replace(/(［|］|，)/g, (s) => {
                    // console.log(s.charCodeAt(0));
                    if (s.charCodeAt(0) === 65339) { // left square brackets
                        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
                    } else if (s.charCodeAt(0) === 65341) { // right square brackets
                        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
                    } else if (s.charCodeAt(0) === 65292) { // comma
                        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
                    }
                    return s;
                });
            }
        },
        clear () {
            this.comment = "";
        },
        prev () {
            this.view = "index";
            this.readonly = false;
        },
        update (cal: boolean) {
            // eslint-disable-next-line
            const sendRecord = async (_url: string, _method: string, json: any) => {
                try {
                    const template = await api(json, _url, _method);

                    if (template) {
                        this.readonly = false;
                        this.view = template;
                        // this.title       = '<?php echo $labels["update"][$this->lang]; ?>';
                    }
                } catch (e) {
                    alert(e.message);
                    console.log(e.message);
                }
            };

            const url = hostname + "/Record/update.php";

            const json = {
                year: this.date ? this.date.slice(0, 4) : '',
                date: this.date ? this.date.slice(5, 10) : '',
                type: this.type,
                amount: Number(this.amount),
                comment: this.comment,
                cal: cal
            };

            sendRecord(url, "PUT", json);
        }
    }
    /*
    , mounted() {

    }
    */
});
</script>
