<template>
  <div class="row mt-md-3">
    <div class="col-12">
      <div id="app.dashboard.lunar" class="alert alert-success alert-dismissible fade show" role="alert">
        <a :href="url">JSON</a> {{lunar}}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import moment from "moment";

export default defineComponent({
    name: 'Lunar',
    data () {
        return {
            url: "https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=fnd&lang=tc",
            lunar: ''
        };
    },
    created () {
        const url = "https://rsk-api.servehttp.com/Lunar/astronomy_day_2021.php";
        fetch(url, {
            method: "GET",
            mode: "cors",
            credentials: "include"
        }).then((res) => res.json()).then((data) => {
            this.lunar = moment().format('YYYY-MM-DD') + " (" + data[moment().format('YYYY-MM-DD')]?.["l"] + ") ";
        });
    }
});
</script>
