
import { defineComponent } from "vue";

export default defineComponent({
    name: "Datatable"
    /*
    props: ["breakfast", "lunch", "dinner", "otherfood"],
    data () {
        return {
            lang: [
                { key: 'date', content: 'Dato' },
                { key: 'food_breakfast', content: 'Matenmanĝo' },
                { key: 'food_lunch', content: 'Tagmanĝo' },
                { key: 'food_dinner', content: 'Vespermanĝo' },
                { key: 'food_other', content: 'Alia manĝaĵo' },
                { key: 'food_comment', content: 'Rimarko' },
                { key: 'live', content: 'Vivo' },
                { key: 'live_comment', content: 'Rimarko' },
                { key: 'traffic', content: 'Trafiko' },
                { key: 'traffic_comment', content: 'Rimarko' },
                { key: 'coffee', content: 'Kafo' },
                { key: 'coffee_comment', content: 'Rimarko' },
                { key: 'book', content: 'Libro' },
                { key: 'book_comment', content: 'Rimarko' },
                { key: 'it', content: 'IT' },

                { key: 'other', content: 'Alio' },
                { key: 'other_comment', content: 'Rimarko' },
                { key: 'total', content: 'Mono' }
            ]
        };
    }
    */
});
