
import { defineComponent } from 'vue';
import {
    WeekModel, ChartView, Presenter,
    getApi,
    hostname,
    mon as lastmon,
    sun as lastsun,
    thismon,
    thissun,
    yesterday
} from "@/functions/chart";

export default defineComponent({
    name: 'Chart',
    data () {
        return {
            noto: [],
            yesterday: 0
        };
    },
    mounted () {
        const _lastweek = new WeekModel(lastmon, lastsun);
        const _lastmodel = _lastweek.getJson();
        const _id = "app.dashboard.chart.lastweek";
        const _lastview = new ChartView(_id);

        const _thisweek = new WeekModel(thismon, thissun);
        const _thismodel = _thisweek.getJson();
        const _thisview = new ChartView("app.dashboard.chart.thisweek");

        let presenter = null;
        presenter = new Presenter(_lastview);
        presenter.setModel(_lastmodel, null, null);
        presenter.setSum("app.dashboard.lastweek", _lastmodel);

        presenter = new Presenter(_thisview);
        presenter.setModel(_thismodel, null, null);
        presenter.setSum("app.dashboard.thisweek", _thismodel);

        const getJson = async () => {
            const start = yesterday;
            const end = yesterday;
            const url = hostname + "/Record/range.php?start=" + start + "&end=" + end;
            const response = await getApi(url);
            const data = await response.json();
            this.yesterday = data?.["datasets"][0]["data"][0];
        };
        const getNoto = async () => {
            try {
                const url = "https://rasikyou.ddns.me/config/noto.json";
                const response = await getApi(url);
                const data = await response.json();
                this.noto = data;
                // console.log(data);
            } catch (e) {
                // console.log(e.message);
            }
        };

        getJson();
        getNoto();
    }
});
