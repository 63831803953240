
import { defineComponent } from 'vue';

interface User {
    username: string;
    password: string;
    lang: string;
}

export default defineComponent({
    name: 'Login',
    methods: {
        goToDashboard: function () {
            this.$router.push('/dashboard');
        },
        postLogin: function () {
            console.log("test");
            const hostname = "https://rsk-api.servehttp.com";
            const _url = hostname + "/User/login.php";
            // eslint-disable-next-line
            const _uname = (document.getElementById("app.login.username") as any).value;
            // eslint-disable-next-line
            const _passwd = (document.getElementById("app.login.password") as any).value;
            // eslint-disable-next-line
            const _lang = (document.getElementById("app.login.lang") as any).value;
            const _json: User = { username: _uname, password: _passwd, lang: _lang !== "none" ? _lang : "" };
            const _changePage = this.goToDashboard;

            fetch(_url, {
                method: 'post',
                mode: "cors",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_json)
            }).then(function (response) {
                return response.json();
            }).then(function () {
                fetch("/user/access.php", {
                    method: 'post',
                    mode: "cors",
                    credentials: "same-origin",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(_json)
                });
                _changePage();
            });
        }
    }
});
