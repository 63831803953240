<template>
    <div class="row">
        <div class="col-12">
            <div
                id="app.dashboard.temperature"
                class="alert alert-info alert-dismissible fade show"
                role="alert"
            ><a :href="url">JSON</a>{{temperature}}</div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Temperature",
    data () {
        return {
            temperature: '',
            url: "https://rsk-api.servehttp.com/Weather/rhrread.php"
        };
    },
    created () {
        fetch(this.url, {
            method: "GET",
            mode: "cors",
            credentials: "include"
        }).then((res) => { return res.json(); }).then((data) => {
            this.temperature =
                    " " +
                    data?.["temperature"]["data"][7]["place"] +
                    " " +
                    data?.["temperature"]["data"][7]["value"] +
                    data?.["temperature"]["data"][7]["unit"];
        });
    }
});
</script>
