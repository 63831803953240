<template>
<div class="content" style="width: 100%; position: static">

    <!--div v-bind:is='view'></div-->
    <div class="animated fadeIn">
        <div class="container-fluid">
            <div class="row" v-show="!dtShow">
                <div class="col-12 col-md-10 col-lg-6">
                    <div class="card">
                                <div class="card-header"></div>
                                <div class="card-body">
                                    <div class="card-title">
                                        <h3 class="text-center" v-show="$route.params.type === 'month'">
                                            {{lang.detailInMonth}}
                                        </h3>
                                        <h3 class="text-center" v-show="$route.params.type === 'days'">
                                            {{lang.detailOnDay}}
                                        </h3>
                                        <h3 class="text-center" v-show="$route.params.type === 'week'">
                                            {{lang.week}}
                                        </h3>
                                    </div>
                                    <hr/>
                                    <div v-show="$route.params.type === 'days' ||  $route.params.type === 'month' ">
                                        <label for="val" class="control-label mb-1">{{lang.month}}</label>
                                        <input id="val" name="val" type="text" class="form-control" min=""
                                            pattern="[0-9]{4}-[0-9]{2}" placeholder=""
                                            v-model="monthVal"
                                            required="">
                                    </div>
                                    <div v-show="$route.params.type === 'week'">
                                        <label for="val" class="control-label mb-1">{{lang.week}}</label>
                                        <input id="val" name="val" type="text" class="form-control" min=""
                                            pattern="[0-9]{4}(-W)[0-9]{2}" placeholder=""
                                            v-model="weekVal"
                                            required="">
                                    </div>
                                    <div class="form-group">
                                        <button id="enter" type="submit" name="" value="true" class="btn btn-lg btn-info btn-block" @click="enterClick">
                                            <span>{{lang.enter}}</span>
                                        </button>
                                    </div>
                                </div><!-- .card-body -->
                    </div><!-- .card -->
                </div><!-- .col -->
            </div><!-- class="row" -->
            <div class="row" v-show="dtShow" style="max-width:85vw">
                <div class="col-12">
                    <div class="form-group" v-show="$route.params.type === 'days'  || $route.params.type === 'month'">
                        <br/>
                        <label for="dt_val" class="mr-2">{{lang.month}}</label>
                        <input id="dt_val" name="val" type="month"
                            min="2013-04" pattern="[0-9]{4}-[0-9]{2}" placeholder="yyyy-MM"
                            v-model="monthVal" required="required" class="mr-2">
                        <br/>
                        <button id="refresh" type="submit" class="btn btn-info" @click="refresh">
                            <span id="">{{lang.refresh}}</span>
                        </button>
                    </div>
                    <div class="form-group" v-show="$route.params.type === 'week'">
                        <br/>
                        <label for="dt_val" class="mr-2">{{lang.week}}</label>
                        <input id="dt_val" name="val" type="week"
                            pattern="[0-9]{4}(-W)[0-9]{1,2}" placeholder="yyyy-Wdd"
                            v-model="weekVal" required="required" class="mr-2">
                        <br/>
                        <button id="refresh" type="submit" class="btn btn-info" @click="refresh">
                            <span id="">{{lang.refresh}}</span>
                        </button>
                    </div>

                    <div class="form-check" v-show="$route.params.view === 'all'">
                        <template v-for="(item, i) in hidden" :key="item.label">
                            <input
                                v-bind:id="item.label"
                                v-bind:name="item.label"
                                type="checkbox" class="form-check-input ml-2"
                                v-model="hidden[i].data"
                                v-on:click='hiddenCol(item.index, hidden[i].data)'
                            >
                            <label v-bind:for="item.label"
                                style="width:120px;padding-left:30px"
                                class="form-check-label ml-2">{{item.label}}</label>
                        </template>
                    </div>
                    <hr/>
                    <datatable-el />
                </div>
            </div>
        </div><!-- div class="container-fluid" -->
    </div>

</div><!-- div class="content" -->
</template>
<script lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { DataTable } from "simple-datatables";
import { defineComponent } from 'vue';
import {
    TableDaysModel,
    TableWeekModel,
    TableMonthModel
} from "@/functions/datatables";
import DatatableEl from '@/components/Datatable.vue';
import moment from "moment";

interface Data {
    dtShow: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    viewEl: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datatableEl: any;

    monthVal: string;
    weekVal: string;

    // breakfast: boolean;
    // lunch: boolean;
    // dinner: boolean;
    // otherfood: boolean;
    // idxClick: number;

    lang: {
        detailInMonth: string;
        detailOnDay: string;
        week: string;
        month: string;
        enter: string;
        refresh: string;
    };

    hidden: {
        data: boolean;
        label: string;
        index: number;
    }[];

}

export default defineComponent({
    components: {
        DatatableEl
    },
    data () {
        const _data: Data = {
            dtShow: false,
            model: [],
            viewEl: document.querySelector(".app-table-data"),
            datatableEl: null,
            monthVal: new Date().toISOString().slice(0, 7),
            weekVal: new Date().toISOString().slice(0, 4) + '-W' + moment().startOf('isoWeek').subtract(1, 'days').subtract(1, 'days').format('w'),

            // breakfast: false,
            // lunch: true,
            // dinner: false,
            // otherfood: false,

            lang: {
                detailInMonth: 'Detalo en Monato',
                detailOnDay: 'Detalo en Tago',
                week: 'Semajno',
                month: 'Monato',
                enter: 'Eniri',
                refresh: 'Refreŝigi'
            },

            hidden: [
                { data: false, index: 1, label: 'Breakfast' },
                { data: false, index: 2, label: 'Lunch' },
                { data: false, index: 3, label: 'Dinner' },
                { data: false, index: 4, label: 'Other-Food' }
            ]
        };
        return _data;
    },
    watch: {
        $route () {
            this.dtShow = false;
        }
    },
    mounted () {
        this.viewEl = document.querySelector(".app-table-data");
    },
    methods: {
        async enterEV (_target: string, val: string) {
            let model = null;

            try {
                if (typeof this?.datatableEl?.destroy !== 'undefined') {
                    this.datatableEl.destroy();
                }
            } catch (e) {
                console.log(e.message);
            }

            if (_target === 'all') {
                if (this.$route.params.type === 'week') {
                    model = await new TableWeekModel(val).getJson();
                    this.model = Object.assign([], model);
                } else if (this.$route.params.type === 'month') {
                    model = await new TableMonthModel(val.slice(0, 4), val.slice(5, 7)).getJson();
                    this.model = Object.assign([], model);
                }
            } else if (_target === 'json') {
                model = await new TableDaysModel(val.slice(0, 4), val.slice(5, 7)).getJson();
                this.model = Object.assign([], model);
            }

            if (this.viewEl) {
                try {
                    this.datatableEl = new DataTable(this.viewEl, model);
                } catch (e) {
                    console.log(e.message);
                }
            }
        },
        process (val: string) {
            this.dtShow = true;
            if (val.match(/\d{4}-\d{2}/)) {
                this.enterEV(this.$route.params.view.toString(), val);
            } else if (val.match(/\d{4}-[wW]\d{1,2}/)) {
                this.enterEV('all', val);
            }
        },
        enterClick () {
            if (this.$route.params.type === 'days' || this.$route.params.type === 'month') {
                this.process(this.monthVal);
            } else if (this.$route.params.type === 'week') {
                this.process(this.weekVal);
            }
        },
        refresh () {
            if (this.$route.params.type === 'days' || this.$route.params.type === 'month') {
                this.process(this.monthVal);
            } else if (this.$route.params.type === 'week') {
                this.process(this.weekVal);
            }
        },
        hiddenCol (num: number, _bool: boolean) {
            const _length = this.model.columns.length;
            if (Array.isArray(this.model.columns)) {
                for (let i = 0; i < _length; i++) {
                    if (this.model.columns?.[i]?.select?.[0] === num && this.model.columns?.[i]?.select?.length === 1) {
                        this.model.columns[i].hidden = _bool;
                        // this.$forceUpdate();
                    }
                }
            }
            if (typeof this?.datatableEl?.destroy !== 'undefined') {
                this.datatableEl.destroy();
            }
            this.model.footer = false;
            this.viewEl = document.querySelector(".app-table-data");
            this.datatableEl = new DataTable(this.viewEl, this.model);
        }
    }
});
</script>
