<template>

  <div class="content" style="width: 100%; position: static">
    <Lunar/>
    <Temperature/>
    <Chart/>
  </div>

</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Temperature from '@/components/Temperature.vue';
import Lunar from '@/components/Lunar.vue';
import Chart from '@/components/Chart.vue';

export default defineComponent({
    name: 'Dashboard',
    components: {
        Lunar,
        Temperature,
        Chart
    }
});
</script>
