
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { DataTable } from "simple-datatables";
import { defineComponent } from 'vue';
import {
    TableDaysModel,
    TableWeekModel,
    TableMonthModel
} from "@/functions/datatables";
import DatatableEl from '@/components/Datatable.vue';
import moment from "moment";

interface Data {
    dtShow: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    viewEl: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datatableEl: any;

    monthVal: string;
    weekVal: string;

    // breakfast: boolean;
    // lunch: boolean;
    // dinner: boolean;
    // otherfood: boolean;
    // idxClick: number;

    lang: {
        detailInMonth: string;
        detailOnDay: string;
        week: string;
        month: string;
        enter: string;
        refresh: string;
    };

    hidden: {
        data: boolean;
        label: string;
        index: number;
    }[];

}

export default defineComponent({
    components: {
        DatatableEl
    },
    data () {
        const _data: Data = {
            dtShow: false,
            model: [],
            viewEl: document.querySelector(".app-table-data"),
            datatableEl: null,
            monthVal: new Date().toISOString().slice(0, 7),
            weekVal: new Date().toISOString().slice(0, 4) + '-W' + moment().startOf('isoWeek').subtract(1, 'days').subtract(1, 'days').format('w'),

            // breakfast: false,
            // lunch: true,
            // dinner: false,
            // otherfood: false,

            lang: {
                detailInMonth: 'Detalo en Monato',
                detailOnDay: 'Detalo en Tago',
                week: 'Semajno',
                month: 'Monato',
                enter: 'Eniri',
                refresh: 'Refreŝigi'
            },

            hidden: [
                { data: false, index: 1, label: 'Breakfast' },
                { data: false, index: 2, label: 'Lunch' },
                { data: false, index: 3, label: 'Dinner' },
                { data: false, index: 4, label: 'Other-Food' }
            ]
        };
        return _data;
    },
    watch: {
        $route () {
            this.dtShow = false;
        }
    },
    mounted () {
        this.viewEl = document.querySelector(".app-table-data");
    },
    methods: {
        async enterEV (_target: string, val: string) {
            let model = null;

            try {
                if (typeof this?.datatableEl?.destroy !== 'undefined') {
                    this.datatableEl.destroy();
                }
            } catch (e) {
                console.log(e.message);
            }

            if (_target === 'all') {
                if (this.$route.params.type === 'week') {
                    model = await new TableWeekModel(val).getJson();
                    this.model = Object.assign([], model);
                } else if (this.$route.params.type === 'month') {
                    model = await new TableMonthModel(val.slice(0, 4), val.slice(5, 7)).getJson();
                    this.model = Object.assign([], model);
                }
            } else if (_target === 'json') {
                model = await new TableDaysModel(val.slice(0, 4), val.slice(5, 7)).getJson();
                this.model = Object.assign([], model);
            }

            if (this.viewEl) {
                try {
                    this.datatableEl = new DataTable(this.viewEl, model);
                } catch (e) {
                    console.log(e.message);
                }
            }
        },
        process (val: string) {
            this.dtShow = true;
            if (val.match(/\d{4}-\d{2}/)) {
                this.enterEV(this.$route.params.view.toString(), val);
            } else if (val.match(/\d{4}-[wW]\d{1,2}/)) {
                this.enterEV('all', val);
            }
        },
        enterClick () {
            if (this.$route.params.type === 'days' || this.$route.params.type === 'month') {
                this.process(this.monthVal);
            } else if (this.$route.params.type === 'week') {
                this.process(this.weekVal);
            }
        },
        refresh () {
            if (this.$route.params.type === 'days' || this.$route.params.type === 'month') {
                this.process(this.monthVal);
            } else if (this.$route.params.type === 'week') {
                this.process(this.weekVal);
            }
        },
        hiddenCol (num: number, _bool: boolean) {
            const _length = this.model.columns.length;
            if (Array.isArray(this.model.columns)) {
                for (let i = 0; i < _length; i++) {
                    if (this.model.columns?.[i]?.select?.[0] === num && this.model.columns?.[i]?.select?.length === 1) {
                        this.model.columns[i].hidden = _bool;
                        // this.$forceUpdate();
                    }
                }
            }
            if (typeof this?.datatableEl?.destroy !== 'undefined') {
                this.datatableEl.destroy();
            }
            this.model.footer = false;
            this.viewEl = document.querySelector(".app-table-data");
            this.datatableEl = new DataTable(this.viewEl, this.model);
        }
    }
});
