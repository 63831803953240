<template>
<div class="content" style="width: 100%; position: static">

    <!--div v-bind:is='view'></div-->
    <div class="animated fadeIn">
        <div class="container-fluid">
            <div class="row" v-show="!dtShow">
                <div class="col-12 col-md-10 col-lg-12">
                        <div class="card" style="height:80vh;max-width:85vw">
                            <div class="card-header">
                                <strong class="card-title">Bordered Table</strong>
                            </div>
                            <div class="card-body" style="overflow-y: scroll;">

                                    <div class='form-group'>

                                    </div><!-- .form-group -->

                                <hr/>

                                <table id="app.table.data" class="app-table-data table table-striped table-bordered" style="margin-right:-50px">
                                    <thead>
                                        <tr>

                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Slug</th>
                                            <th>Description</th>
                                            <th>View</th>

                                        </tr>
                                        <tr>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td></td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div><!-- .card-body -->
                        </div><!-- .card -->
                </div>
            </div><!-- class="row" -->
        </div><!-- div class="container-fluid" -->
    </div>

</div><!-- div class="content" -->
</template>
<script lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { DataTable } from "simple-datatables";
import { defineComponent } from 'vue';

interface Data {
    id: string;
    name: string;
    slug: string;
    description: string;
}

const getApi = (_url: string) => {
    return fetch(_url, {
        method: "GET",
        mode: "cors",
        credentials: "include"
    });
};

const getJson = async (el: Element): Promise<void> => {
    const response = await getApi("https://rsk-api.servehttp.com/Bookstack/books.php");
    const data = await response.json();
    console.log(data);
    const _data = data.data.map((obj: Data) => [obj.id, obj.name, obj.slug, obj.description, obj.id]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _datatable = new DataTable(el, {
        data: { data: _data },
        perPage: 50,
        searchable: true,
        fixedHeight: false,
        perPageSelect: [50, 100],
        columns: [
            {
                select: 4,
                render: (data: string) => {
                    return "<a href='https://rsk-api.servehttp.com/Bookstack/export.php?id=" + data + "'  target='_blank'>view html</a>";
                }
            }
        ]
    });
};

export default defineComponent({
    data () {
        return {};
    },
    mounted () {
        const el = document.querySelector(".app-table-data");
        if (el) {
            getJson(el);
        }
    }
});
</script>
