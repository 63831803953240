<template>
    <div class="card" style="height:80vh">
        <div class="card-header">
            <strong class="card-title">Bordered Table</strong>
        </div>
        <div class="card-body" style="overflow-y: scroll;">
        <table id="app.table.data" class="app-table-data table table-striped table-bordered" style="margin-right:-50px">
            <!--thead-->
                <!--tr v-show="$route.params.view === 'json'">
                    <th>Year</th>
                    <th>Date</th>
                    <th>Cost</th>
                    <th>View Detail</th>
                </tr-->
                <!--tr v-if="$route.params.view === 'all' ">
                    <th v-for="item in lang" :key="item.key">{{item.content}}</th>
                </tr-->
            <!--/thead-->
            <tbody>
                <tr v-show="$route.params.view === 'json'">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
            <!--tfoot-->
                <!--tr v-show="$route.params.view === 'json'">
                    <th>Year</th>
                    <th>Date</th>
                    <th>Cost</th>
                    <th>View Detail</th>
                </tr-->
                <!--tr v-show="$route.params.view === 'all'">
                    <th v-for="item in lang" :key="item.key">{{item.content}}</th>
                </tr-->
            <!--/tfoot-->
        </table>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Datatable"
    /*
    props: ["breakfast", "lunch", "dinner", "otherfood"],
    data () {
        return {
            lang: [
                { key: 'date', content: 'Dato' },
                { key: 'food_breakfast', content: 'Matenmanĝo' },
                { key: 'food_lunch', content: 'Tagmanĝo' },
                { key: 'food_dinner', content: 'Vespermanĝo' },
                { key: 'food_other', content: 'Alia manĝaĵo' },
                { key: 'food_comment', content: 'Rimarko' },
                { key: 'live', content: 'Vivo' },
                { key: 'live_comment', content: 'Rimarko' },
                { key: 'traffic', content: 'Trafiko' },
                { key: 'traffic_comment', content: 'Rimarko' },
                { key: 'coffee', content: 'Kafo' },
                { key: 'coffee_comment', content: 'Rimarko' },
                { key: 'book', content: 'Libro' },
                { key: 'book_comment', content: 'Rimarko' },
                { key: 'it', content: 'IT' },

                { key: 'other', content: 'Alio' },
                { key: 'other_comment', content: 'Rimarko' },
                { key: 'total', content: 'Mono' }
            ]
        };
    }
    */
});
</script>
