
import { defineComponent } from "vue";

export default defineComponent({
    name: "Temperature",
    data () {
        return {
            temperature: '',
            url: "https://rsk-api.servehttp.com/Weather/rhrread.php"
        };
    },
    created () {
        fetch(this.url, {
            method: "GET",
            mode: "cors",
            credentials: "include"
        }).then((res) => { return res.json(); }).then((data) => {
            this.temperature =
                    " " +
                    data?.["temperature"]["data"][7]["place"] +
                    " " +
                    data?.["temperature"]["data"][7]["value"] +
                    data?.["temperature"]["data"][7]["unit"];
        });
    }
});
